<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form class="custom-form" ref="loanForm" @submit.prevent="saveLoan">
            <v-row dense>
                <v-col 
                    cols="12" 
                    md="3"
                    style="height: 272px"
                >
                    <v-combobox
                        label="Employee"
                        dense
                        outlined
                        hide-details
                        :items="employees"
                        v-model="selectedEmployee"
                        item-text="display_name"
                        item-value="id"
                        class="mb-1"
                        @focus="getEmployees"
                        :rules="[() => !!loan.employee_id || '']"
                        :error-messages="validation_errors.employee_id"
                    ></v-combobox>
                    <v-card :dark="selectedEmployee ? true : false" :color="selectedEmployee ? 'primary' : '#ECEFF1'" :elevation="1">
                        <v-card-text class="text-center pa-2">
                            <v-avatar
                                color="deep-purple darken-4"
                                size="120"
                                class="mb-4"
                            >
                                <v-img v-if="selectedEmployee" :src="$store.state.host+selectedEmployee.image"></v-img>
                                <v-img v-else src=""></v-img>
                            </v-avatar>
                            <h2 class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.emp_id : 'Employee ID' }}
                            </h2>
                            <h1 class="subtitle-2">{{ selectedEmployee ? selectedEmployee.name : 'Employee name' }}</h1>
                            <p class="caption mb-0">
                                {{ selectedEmployee && selectedEmployee.designation ? selectedEmployee.designation.name : 'Designation' }}, 
                                {{ selectedEmployee && selectedEmployee.department ? selectedEmployee.department.name : 'Department' }}
                            </p>
                            <p class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.phone : 'Phone' }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                >
                    <v-card
                        height="100%"
                        :elevation="1"
                    >
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Loan Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Tr. ID</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model="loan.code"
                                        dense
                                        outlined
                                        hide-details
                                        autocomplete="off"
                                        readonly
                                        :rules="[() => !!loan.code || '']"
                                        :error-messages="validation_errors.code"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Date</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-menu 
                                        max-width="290"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                v-model="loan.entry_date"
                                                :rules="[() => !!loan.entry_date || '']"
                                                :error-messages="validation_errors.entry_date"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            v-model="loan.entry_date"
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Loan Amount</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model="loan.loan_amount"
                                        dense
                                        outlined
                                        hide-details
                                        autocomplete="off"
                                        @input="calculation"
                                        :rules="[() => !!loan.loan_amount || '']"
                                        :error-messages="validation_errors.loan_amount"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">T.Installment</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model="loan.total_installment"
                                        dense
                                        outlined
                                        hide-details
                                        autocomplete="off"
                                        @input="calculation"
                                        :rules="[() => !!loan.total_installment || '']"
                                        :error-messages="validation_errors.total_installment"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Inst Amount</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model="loan.installment_amount"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                        :rules="[() => !!loan.installment_amount || '']"
                                        :error-messages="validation_errors.installment_amount"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Start From</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-menu 
                                        max-width="290"
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                v-model="loan.start_from"
                                                :rules="[() => !!loan.start_from || '']"
                                                :error-messages="validation_errors.start_from"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            type="month"
                                            v-model="loan.start_from"
                                            @input="menu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Complete At</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field
                                        v-model="loan.complete_at"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                        :rules="[() => !!loan.complete_at || '']"
                                        :error-messages="validation_errors.complete_at"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-col>
                <v-col cols="12" md="5">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Other Information</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row class="pt-2">
                                <v-col cols="4" class="py-1 caption">Approved By</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-combobox
                                        label="Approved By"
                                        dense
                                        outlined
                                        hide-details
                                        :items="employees"
                                        item-text="display_name"
                                        item-value="id"
                                        class="custom-form"
                                        v-model="selectedApprovedBy"
                                        @focus="getEmployees"
                                        :rules="[() => !!loan.approved_by || '']"
                                        :error-messages="validation_errors.approved_by"
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Remark</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-textarea
                                        dense
                                        outlined
                                        v-model="loan.remark"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" class="text-right">
                    <v-btn height="30px" dark color="light-blue darken-2 mr-1" :loading="loading" :elevation="1" type="submit">Save</v-btn>
                    <v-btn height="30px" dark color="deep-orange" :elevation="1" @click="resetForm">Cancel</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-row v-if="loans.length > 0">
            <v-col cols="12" class="py-0">
                <v-toolbar dense color="white" :elevation="1" height="30px">
                    <v-toolbar-title class="subtitle-2">Previous Loan Information</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn
                        color="primary"
                        class="white--text"
                        small
                        dense
                        height="25"
                        @click="print"
                    >
                        <v-icon left small dense> mdi-printer </v-icon>
                        Print
                    </v-btn>
                </v-toolbar>
            </v-col>
            <v-col cols="12" md="12" class="py-1" id="reportContent">
                <v-simple-table dense class="record_table">
                    <thead>    
                        <tr class="record_heading_tr">
                            <th>SL.</th>
                            <th>Tr. ID</th>
                            <th>Entry Date</th>
                            <th>Start From</th>
                            <th>Com. At</th>
                            <th>Loan Amount</th>
                            <th>Due Amount</th>
                            <th>Installment</th>
                            <th>Due Inst.</th>
                            <th>Inst. Amount</th>
                            <th>Approved By</th>
                            <th>Remark</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-for="(record, sl) in loans" :key="sl">
                            <td>{{++sl}}</td>
                            <td>{{record.code}}</td>
                            <td>{{record.entry_date}}</td>
                            <td>{{record.start_from | dateFormat('YYYY-MM')}}</td>
                            <td>{{record.complete_at | dateFormat('YYYY-MM')}}</td>
                            <td>{{record.loan_amount}}</td>
                            <td>{{record.due_amount}}</td>
                            <td>{{record.total_installment}}</td>
                            <td>{{record.due_installment}}</td>
                            <td>{{record.installment_amount}}</td>
                            <td>{{record.approved_by_name}}</td>
                            <td>{{record.remark}}</td>
                            <td>{{record.status_text}}</td>
                            <td>
                                <v-tooltip bottom v-if="$store.state['user'].role != 'User' && record.total_installment == record.due_installment">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            @click="editLoan(record)"
                                            color="primary"
                                            v-on="on"
                                            >mdi-circle-edit-outline</v-icon
                                        >
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="$store.state['user'].role == 'Admin' && record.total_installment == record.due_installment">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            @click="deleteLoan(record)"
                                            color="error"
                                            v-on="on"
                                            >mdi-delete-circle-outline</v-icon
                                        >
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data(){
        return{
            employees: [],
            selectedEmployee: null,
            selectedApprovedBy: null,
            loan: {
                id: null,
                code: '',
                employee_id: null,
                entry_date: this.toISOLocal().substr(0, 10),
                start_from: this.toISOLocal(new Date(new Date().setMonth(new Date().getMonth() + 1))).substr(0, 7),
                loan_amount: '',
                total_installment: '',
                installment_amount: 0,
                complete_at: '',
                remark: '',
                approved_by: null,
            },
            loans: [],
            loading: false,
            menu: false,
            menu2: false,
            validation_errors: {}
        }
    },
    watch: {
        'loan.entry_date'(date){
            this.loan.start_from = this.toISOLocal(new Date(new Date(date).setMonth(date.substr(5,2)))).substr(0, 7);
        },  
        'loan.start_from'(date){
            this.calculationCompeleteDate();
        },
        selectedEmployee(emp){
            this.loan.employee_id       = null;
            this.getLoans();
            if(!emp || emp.id == undefined){
                return;
            }
            this.loan.employee_id     = emp.id;
        },
        selectedApprovedBy(emp){
            this.loan.approved_by = null;
            if(!emp || emp.id == undefined){
                return;
            }
            this.loan.approved_by = emp.id;
        },
    },
    created () {
        this.getLoanCode();
    },
    methods: {
        async getLoanCode(){
            await this.$store.dispatch("loan/getNewLoanCode");
            this.loan.code = this.$store.getters["loan/newCode"];
        },
        async getLoans(){
            this.loans = [];
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){
                this.loans = await this.$store.dispatch('loan/getLoans',{
                    apiParams: {
                        employee_id: this.selectedEmployee.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        calculation(){
            if(this.loan.total_installment > 0 && this.loan.loan_amount > 0)
                this.loan.installment_amount = Math.ceil((this.loan.loan_amount / this.loan.total_installment));

            // calculation complete date
            this.calculationCompeleteDate();
        },
        calculationCompeleteDate(){
            this.loan.complete_at = '';
            if(this.loan.total_installment > 0){
                let completeDate = new Date(this.loan.start_from);
                completeDate.setMonth(completeDate.getMonth() + (parseInt(this.loan.total_installment) - 1));
                this.loan.complete_at = this.toISOLocal(new Date(completeDate)).substr(0, 7);
            }
        },
        async getEmployees(){
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
        },
        async saveLoan(){

            if(!this.$refs.loanForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;
            let url = "/add_loan";
            if(this.loan.id){
                url = '/update_loan';
            }

            let data = {
                url,
                loan: this.loan
            }

            let resObj = await this.$store.dispatch('loan/saveLoan', data);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;
            if(resObj.success) {
                this.resetForm();
            }
        },
        resetForm(){
            this.selectedEmployee = null;
            this.selectedApprovedBy = null;

            this.validation_errors = {};

            this.loan = {
                id: null,
                code: '',
                employee_id: null,
                entry_date: this.toISOLocal().substr(0, 10),
                start_from: this.toISOLocal(new Date(new Date().setMonth(new Date().getMonth() + 1))).substr(0, 7),
                loan_amount: '',
                total_installment: '',
                installment_amount: 0,
                complete_at: '',
                remark: '',
                approved_by: null,
            };

            this.$refs.loanForm.resetValidation();
            this.getLoanCode();
        },
        editLoan(loan){
            this.selectedApprovedBy = {
                id : loan.approved_by,
                display_name: loan.approved_by_name,
            }

            this.loan = {
                id                : loan.id,
                code              : loan.code,
                employee_id       : loan.employee_id,
                entry_date        : loan.entry_date,
                start_from        : loan.start_from.substr(0, 7),
                complete_at       : loan.complete_at.substr(0, 7),
                loan_amount       : loan.loan_amount,
                total_installment : loan.total_installment,
                installment_amount: loan.installment_amount,
                remark            : loan.remark,
            }
        },
        async deleteLoan(loan){
            if (!confirm("Are you sure?")) return;

            await this.$store.dispatch("loan/deleteLoan", loan.id);
            this.getLoans();
        },
        async print(){
            let title = 'Loan Record of ';
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){
                title += this.selectedEmployee.name;
            }

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            const prtHtml = document.getElementById('reportContent').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                    <div class="container container--fluid">
                        <div class="row row--dense">
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                            </div>
                            <div class="col-8 px-2 text-center">
                                <span class="font-weight-black">
                                    ${company_profile.name}
                                </span>
                                <br>
                                <span>${company_profile.address}</span>
                                <br>
                                <span>
                                    Email:${company_profile.email}, Contact:${company_profile.phone}
                                </span>
                            </div>
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+'linkup.png'}">
                            </div>
                            <div class="col-12 px-2 text-center">
                                <p class="font-weight-black custom_header">
                                    ${title}
                                </p>
                            </div>
                            <div class="col col-12 px-2">
                                ${prtHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`);

            WinPrint.document.head.innerHTML += `
                <style>
                    .v-data-table__wrapper{
                        overflow: unset;
                    }
                    
                    .record_heading_tr th{
                        text-align: center !important;
                    }
                    .custom_header{
                        background-color: #0000001f;
                        border-radius: 4px;
                        margin-bottom: 5px !important;
                    }
                    tr:hover{
                        background: unset !important;
                    }
                    .record_heading_tr:hover{
                        background: #4caf7773 !important;
                    }
                    @media print {
                        table.record_table { page-break-after:auto }
                        table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table thead { display:table-header-group }
                        table.record_table tfoot { display:table-footer-group }
                    }
                </style>
            `;

            let rows = WinPrint.document.querySelectorAll('.record_table tr');
            rows.forEach(row => {
                row.lastChild.remove();
            })

            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }
    },
}
</script>

<style scoped>
    .record_table{
        margin-bottom: 5px;
    }
    .record_table table, .record_table th, .record_table td {
        border: 1px solid #dee2e6;
        border-collapse: collapse;
        padding: 0 !important;
        
    }
    .record_table th, .record_table td {
        padding: 2px !important;
        font-size: 11px !important;
        text-align: center !important;
        height: 0 !important;
    }
    .record_heading_tr{
        background: #4caf7773;
    }
</style>